* {
    box-sizing: border-box;
    font-family: sans-serif;
}

html,
body {
    font-family: sans-serif;
    font-size: 100%;
}

html,
body,
.wrapper,
.sct {
    height: 100%;
    margin: 0;
}

a {
    text-decoration: none;
    color: rgb(22, 185, 185);
}

h1 {
    font-size: 6rem;
}

h2 {
    font-size: 3rem;
}

h3 {
    font-size: 2rem;
}

input:active,
input:focus,
input:visited,
input:hover {
    outline: none;
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"] {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    padding: 1.8rem 1.6rem;
    background: #fff;
    border: 1px solid rgb(212, 212, 212);
    font-family: sans-serif;
    font-weight: 600;
    color: rgb(78, 78, 78);
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 1rem;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -o-transition: all .3s;
    -moz-transition: all .3s;
    -webkit-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

input[type="submit"] {
    display: block;
    width: 100%;
    margin: 1.5rem 0;
    padding: 1.2rem;
    background: rgb(30, 108, 172);
    border: 1px solid rgb(212, 212, 212);
    font-family: sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    color: rgb(247, 247, 247);
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 1rem;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -o-transition: all .3s;
    -moz-transition: all .3s;
    -webkit-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

input[type="submit"]:active,
input[type="submit"]:focus,
input[type="submit"]:visited,
input[type="submit"]:hover {
    background: rgb(34, 156, 255);
}

button,
input[type="submit"] {
    cursor: pointer;
}

.text-center {
    text-align: center;
}

form>h3 {
    text-align: center;
}

.forgot-remember {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        "centerLeft centerRight";
}

.sct {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.brand>h3 {
    padding: .75rem;
    font-weight: 800;
    outline: 3px solid #fff;
    color: #fff;
}

.login {
    grid-area: centerRight;
}

.login>form {
    width: 400px;
    margin: 0 3rem;
}

@media (max-width: 768px) {
    .wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: minmax(20%, 80vmax);
        grid-template-areas:
            "centerLeft"
            "centerRight";
    }

    .login>form {
        width: 100%;
        margin: 0 3rem;
    }
}